/* 商品详情（基于product.vue） */
<template>
  <div class="product_detail">
    <nav-header :isShowLeftMenu="false" ></nav-header>
    <!-- <breadcrumb-nav :breadcrumbList="breadcrumbList"></breadcrumb-nav> -->

    <div v-if="localStorageType==1">
      <div class="detail_video">
      <div class="container">
        <div class="detail_content_top">
          <div class="detail_content_top_left">
            <video class="detail_video"
                   :src="detailData.productVideoPath"
                   controls
                   preload
            ></video>
            <div class="company-info">
              <div class="item-style-26" style="paddingBottom: .06rem">服务商：<span>{{detailData.companyName}}</span></div>
<!--              <div class="item-style-26">
                联系方式
                <span style="margin-left: 18px">{{detailData.customerWay + ' '}}</span>
                <span>{{detailData.customerEmail}}</span>
              </div>-->
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div v-if="localStorageType==2">
      <div class="detail_video">
        <!-- <div class="container"> -->
        <!-- <div class="detail_content_top"> -->
        <!-- <div class="detail_content_top_left"> -->
        <!--            <video class="video"
                    :src="detailData.productVideoPath"
                    controls
                    preload
                    ></video>-->
        <img controls="controls" preload=""
             src="http://61.172.179.73:8333/new-code-product/1cbf0c6d-0431-4e44-b26f-cc45ac6062ad.png" __idm_id__="2056193" width="100%">

                    <div class="company-info">
                      <div class="item-style-26" style="paddingBottom: .06rem">服务商：<span>{{detailData.companyName}}</span></div>
<!--                      <div class="item-style-26">
                        联系方式
                        <span style="margin-left: 18px">{{detailData.customerWay + ' '}}</span>
                        <span>{{detailData.customerEmail}}</span>
                      </div>-->
                    </div>
        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>

    <div class="detail_describe">
      <div class="detail_describe_title">
        <img class="logo" :src="detailData.productLogoPath" />
        <div class="text">
          {{detailData.productName}}
        </div>
      </div>
      <div class="detail_describe_img">
        <img src="/imgs/product/iconsm.png" />
      </div>
      <div class="detail_describe_content">
         {{detailData.productIntroduction}}
      </div>
      <div class="detail_describe_copy">
        <div>原始著作权：{{detailData.originalCopyrightUnit}}</div>
        <div>发行单位：{{detailData.originalIssuingUnit}}</div>
        <div>发行版本：{{detailData.productVersion}}</div>
        <div>发行时间：{{detailData.createTime}}</div>
        <div>确权链：{{detailData.chainName}}</div>
        <div class="chainHash">
          <span>确权：</span>
          <span class="chainHashInfo">{{detailData.chainHashInfo}}</span>
        </div>
      </div>
      <div 
        :class="{'not-click': detailData.showSign === 1 ,'btn-gray': detailData.showSign === 1}"
        class="btn" 
        @click="contractClick"
      >立即签约</div>

      <div class="detail_describe_pos">
        <div>适用于：<span class="color-b">{{detailData.environmentList}}</span></div>
        <div>面向客群：<span class="color-b">{{detailData.faceCustomerGroup}}</span></div>
        <div>合作方式：{{detailData.cooperationWay}}</div>
        <div>合作周期： {{detailData.cooperationPeriod}}</div>
        <div>使用方式：<span class="color-b">{{detailData.usage}}</span></div>
        <div class="secondaryDevelopment">
          <span>二次开发：</span>
          <span class="secondaryDevelopmentInfo">
            <span class="secondaryDevelopmentOne">{{detailData.secondaryDevelopmentOne}}</span>
            <span>{{detailData.secondaryDevelopmentTwo}}</span>
          </span>
        </div>
      </div>

      <div 
        :class="{'not-click': detailData.showSign === 1 ,'btn-gray': detailData.showSign === 1}"
        class="down-btn btn" 
        id="downloadCode" 
        @click="download"
        >立即下载</div>
    </div>

    <div class="detail_content_nav">
        <div class="detail_content_nav_info">
          <!--暂时屏蔽商品详情、用户案例、服务支持、申请演示-->
<!--          <div class="detail_content_nav_list">
            <div class="nav-item" @click="checkNav(item.id,$event)" :class=" activeName === item.id? 'selected':''" v-for="item in navList" :key="item.id">
              <a :href="'#'+item.id">{{item.name}}</a>
            </div>
          </div>
          <div  class="productDetails editor-content-view">
            <div class="title">商品详情</div>
            <div id="productDetails">
            </div>
          </div>-->
          <div class="detailsDemo editor-content-view">
            <div class="title">在线演示</div>

            <div id="detailsDemo">
            </div>
            <div class="demo-button">
              <button class="btn" @click="advisoryService">咨询客服</button>
              <button 
                :class="{'not-click': detailData.showSign === 1 ,'btn-gray': detailData.showSign === 1}"
                class="btn"
                @click="applyClick"
               >申请演示</button>
            </div>
          </div>
<!--          <div class="userCase editor-content-view">
            <div class="title">用户案例</div>
            <div id="userCase"></div>
          </div>
          <div class="serviceSupport editor-content-view">
            <div class="title">服务支持</div>
            <div id="serviceSupport"></div>
          </div>
          <div id="apply" class="apply">
            &lt;!&ndash; <div class="title" style="marginBottom: 16px">申请演示</div> &ndash;&gt;
            <button  
              :class="{'not-click': detailData.showSign === 1 ,'btn-gray': detailData.showSign === 1}" 
              class="btn" 
              @click="connectClick"
            >联系我们</button>
          </div>-->
        </div>
    </div>

    <el-dialog
      title="下载进度"
      :visible.sync="dialogVisible"
      width="360px"
      :show-close="false"
      :close-on-click-modal="false"
    >
    <el-progress :percentage="progress"></el-progress>
    <span slot="footer" class="dialog-footer">
      <el-button @click="() => {
        dialogVisible = false
        isStop = true
      }">取消下载</el-button>
      <el-button
        v-show="!isDone"
        @click="() => {
        isPause = !isPause
        if(!isPause) {
          sectionDownload(start, end, downUrl);
        }
      }">{{ isPause? '继续' : '暂停' }}</el-button>
      <el-button type="primary"  :disabled="!isDone" @click="() => {
        downloadFile(entryContent ,type, filename)
        entryContent = []
        dialogVisible = false
      }">下载完成</el-button>
    </span>
    </el-dialog>

    <PageFooterMobile></PageFooterMobile>

    <contractModal ref="contractModalRef"></contractModal>
    <consult-modal ref="consultModal"></consult-modal>
    <useModal ref="useModal" type="1"></useModal>
  </div>
</template>
<script>
import {TabPane, Tabs, Checkbox, Dialog,Breadcrumb,BreadcrumbItem} from "element-ui";
import useModal from "../../components/productDetail/useModal";
import ConsultModal from "../../components/productDetail/consultModalMobile";
import contractModal from "../../components/productDetail/contractModal";
// import BreadcrumbNav from "../../components/productDetail/breadcrumbNav";
import NavHeader from "../../components/NavHeader-mobile.vue";
import {getInfo} from "../../api/login";
import { productDetail, getApplyContractStatus, downloadSkuFile} from "../../api/product"
import {mapMutations, mapState} from "vuex";
import { htmlFontSize, isMobile } from '../../util/common'
import PageFooterMobile from '../../components/pageFooterMobile.vue'
export default {
  components: {
    // BreadcrumbNav,
    PageFooterMobile,
    NavHeader,
    useModal,
    ConsultModal,
    contractModal,
    [Tabs.name]: Tabs ,
    [TabPane.name]: TabPane,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Breadcrumb.name]:Breadcrumb,
    [BreadcrumbItem.name]:BreadcrumbItem,
  },
  name: "detail",
  data() {
    return {
      activeName: 'productDetails',
      id: this.$route.params.id,
      detailData: {},
      navList:[
        {name: '商品详情', id:'productDetails'},
        {name: '在线演示',id:'detailsDemo'},
        {name: '用户案例',id:'userCase'},
        {name: '服务与支持',id:'serviceSupport'},
        // {name: '申请演示',id:'apply'},
      ],
      breadcrumbList:[
        {name: '开源企业', path:'/product'},
        {name: '详情', path:''},
      ],
      pageList:[
        {name: '公司首页', page:'/index', id:'content_img1'},
      ],
      entryContent: [],
      dialogVisible: false,
      progress: 0,
      isStop: true,
      filename: '',
      type: '',
      isDone: false,
      isPause: false,
      start: 0,
      end: 1024 * 1024 * 3,
      offset: 1024 * 1024 * 3,
      downUrl: '',
      localStorageType: localStorage.getItem("type") || 1,
    };
  },
  computed: {
    ...mapState('user', ['token'])
  },
  mounted() {
    if(!isMobile()) {
      this.$router.push('/detail/' + this.$route.params.id)
      return
    }    
    htmlFontSize()
    let token = this.$cookie.get('COOKIE-ACCESS-TOKEN-KEN') || localStorage.getItem('token')
    if(token) {
      localStorage.setItem('token', token)
      getInfo().then(res => {
        if(res.code === 200) {
          this.updateUserInfo(res.data)
        }
      })
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    window.scrollTo(0,0)
    this.getData();
  },
  methods: {
    ...mapMutations('user', ['updateUserInfo']),

    checkNav(id,e) {
      e = e ||event
      e.preventDefault()
      this.activeName = id
      let targetEle = document.getElementById(id)
      let h = targetEle.offsetTop
      window.scrollTo(0,h)
    },
    downloadFile(data, type = '', fileName = '') { 
      let blob = new Blob([...data], {type: `${type}`});
      // 获取heads中的filename文件名
      let downloadElement = document.createElement('a');
      // 创建下载的链接
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      // 下载后文件名
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      // 点击下载
      downloadElement.click();
      // 下载完成移除元素
      document.body.removeChild(downloadElement);
      // 释放掉blob对象
      window.URL.revokeObjectURL(href);
    },
    sectionDownload(start, end, url) { 
      if (this.isStop) return
      if (this.isPause) return
      let token = localStorage.getItem("token") || ''
      let that = this
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", "Bearer " + token);
      xhr.setRequestHeader("Range", `bytes=${start}-${end}`);
      xhr.responseType="blob"
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) { 
          if (xhr.status == 206) {
            let ranges = xhr.getResponseHeader('Content-Range').split(' ')[1].split('/')
            let total = Number(ranges[1])
            let currentNum = Number(ranges[0].split('-')[1]) + 1
            that.progress = Number((currentNum / total * 100).toFixed(0))

            that.entryContent.push(xhr.response);
            that.start = end + 1
            that.end = end + 1024 * 1024 * 3
            //请求其中的某个部分
            that.sectionDownload(end + 1, end + that.offset, url);
          } else if (xhr.status == 416) {
            let filename = xhr.getResponseHeader('content-disposition').split(';')[1].split('=')[1].split('.')[0]
            that.filename = decodeURI(filename)
            that.type = xhr.getResponseHeader('content-type')
            // that.downloadFile(that.entryContent ,type, filename)
            that.isStop = true
            that.isDone = true
          } else {
            that.isStop = true
            this.isPause = true
            console.log(xhr);
          }
        }
      };
      xhr.onerror = function () { 
        that.isStop = true
        this.isPause = true
      }
      xhr.send();
    },
    download() { 
      downloadSkuFile({productId:this.id}).then((res) => {
        // 下载
        if(res.code === 200) {
          let a = document.createElement('a')
          a.href = res.data
          a.click()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 断点下载
    async downloadP() {
      if (localStorage.getItem('token')) {
        let data = null
        try {
          data = await getApplyContractStatus({ productId: this.id })
        } catch (err) { 
          this.$message.error(err)
          return
        }
        if (data.data !== 2) { 
          this.$message.error("您还未完成签约，请点击【立即签约】按钮进行签约！")
          return
        }
        this.progress = 0
        this.dialogVisible = true
        this.isDone = false
        this.entryContent = []
        this.start = 0
        this.end = this.offset
        this.isPause = false
        this.isStop = false
        this.downUrl = `/new_gateway/codepower-rest-api/apply/divideDownload?productId= ${this.id}`
        this.sectionDownload(this.start,this.end, `/new_gateway/codepower-rest-api/apply/divideDownload?productId= ${this.id}`)
        // downloadSkuFile({productId:this.id}).then((res) => {
        //   // 下载
        //   if(res.code === 200) {
        //     let a = document.createElement('a')
        //     a.href = res.data
        //     a.click()
        //   } else {
        //     this.$message.error(res.message)
        //   }
        // })
      } else {
        this.$Bus.$emit('showLogin','show')
      }
    },

    getIsLogin() {
      if(localStorage.getItem('token') || this.$store.state.user.token){
        return getApplyContractStatus({productId:this.id}).then( res => {
          if(res.code === 200) {
            if(res.data === 0) {
              this.$message.info('您的签约正在审核中，请留意短信通知！')
              return false
            } else if(res.data === 1) {
              this.$message.info('您的签约正在审核中，请留意短信通知！')
              return false
            } else if(res.data === 2){
              this.$message.info('您的签约已通过，请点击【立即下载】按钮进行下载！')
              return false
            } else if(res.data === -1) {
              this.$message.info('申请不通过，不能重复申请')
              return false
            } else if(res.data === -2) {
              return true
            } else {
              return false
            }
          } else {
            this.$message.error(res.message)
            return false
          }
        }).catch( (err) => {
          this.$message.error(err.message)
          return false
        })
      } else {
        this.$Bus.$emit('showLogin','show')
        return new Promise(() => {})
      }
    },

    // 立即签约
    contractClick() {
      let result = this.getIsLogin()
      result.then( (res) => {
        if(res) {
          this.$refs.contractModalRef.showModal()
        }
      })
    },

    // 咨询客服
    advisoryService() {
      this.$refs.consultModal.showDialog()
    },


    // 申请演示
    applyClick() {
      let result = this.getIsLogin()
      result.then((res) => {
        if(res) {
          this.$refs.useModal.showDialog()
        }
      })
    },


    // 联系我们
    connectClick(){
      let result = this.getIsLogin()
      result.then(res => {
        if(res) {
          this.$refs.useModal.showDialog()
        }
      })
    },


    // 初始化产品详情数据
    getData() {
      productDetail({productId: this.id, isMobile: true}).then(res => {
        if(res.code === 200) {
          // 详情数据
          this.detailData = res.data
          for(let key in this.detailData){
            if(!this.detailData[key]) {
              this.detailData[key] = '暂无数据'
            }
          }

          let tel = this.detailData.chainHash;
          tel = "" + tel;
          this.detailData.chainHashInfo = tel.substr(0,10) + "***********" + tel.substr(35)
          // console.log(this.detailData.chainHashInfo);
          let secondaryDevelopment = this.detailData.secondaryDevelopment.split('，')
          if(secondaryDevelopment.length > 1) {
            this.detailData.secondaryDevelopmentOne = secondaryDevelopment[0]
            this.detailData.secondaryDevelopmentTwo = '，'+secondaryDevelopment[1]
          } else {
            this.detailData.secondaryDevelopmentOne = secondaryDevelopment[0]
            this.detailData.secondaryDevelopmentTwo = ''
          }
          // console.log(this.detailData.chainHash.length)
          // document.getElementById('productDetails').innerHTML = res.data.productDetailsMobile
          document.getElementById('detailsDemo').innerHTML = res.data.demoInfoMobile
          // document.getElementById('userCase').innerHTML = res.data.userCaseMobile
          // document.getElementById('serviceSupport').innerHTML = res.data.platformSupportScopeMobile
        }
      })
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },


};
</script>
<style lang="scss" scoped>
.product_detail {
  width: 100vw;
}
.detail_video {
  width: 100vw;
  padding: .5rem 0 0 0;
  background-color: #F5F5F5;
  .video {
    width: 100%;
  }
  .company-info {
    padding: .36rem .46rem;
    div {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}
.detail_describe {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .65rem .28rem 0;
  width: 100vw;
  background-color: #fff;
  .detail_describe_title {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: .66rem;
      height: 100%;
    }
    .text {
      padding-left: .1rem;
      font-size: .34rem;
      color: #000;
      line-height: .48rem;
    }
  }
  .detail_describe_img {
    margin-top: .4rem;
    img {
      width: 6.37rem;
      height: 100%;
    }
  }
  .detail_describe_content {
    padding: .18rem 0;
    font-size: .28rem;
    line-height: .6rem;
    font-weight: 400;
    color: #333333;
  }
  .detail_describe_copy {
    padding: .2rem 0 .5rem;
    line-height: .46rem;
    width: 100%;
    font-size: .28rem;
    font-weight: 400;
    color: #6D7278;
  }
  .detail_describe_pos {
    padding: .6rem 0 .5rem;
    line-height: .46rem;
    width: 100%;
    font-size: .28rem;
    font-weight: 400;
    color: #6D7278; 
  }
}
.detail_content {
  overflow: hidden;
  padding: .88rem 0 .32rem;
  .detail_content_top_left {
    width: 430px;
    float: left;
    video{
      width: 100%;
      height: 100%;
    }
    .company-info {
      margin-top: 32px;
      div {
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
  .detail_content_top_right {
    float: left;
    width: calc(100% - 454px);
    margin-left: 24px;
    .detail_content_top_right_info {
      display: flex;
      .info-logo {
        width: 70px;
        height: 100%;
        margin-right: 16px;
      }
      .detail_content_top_right_title {
        display: flex;
        justify-content: space-between;
        h2 {
          font-size: 25px;
          color: $colorT1;
        }
      }
    }
    .detail_content_top_right_introduce {
      font-size: 13px;
      margin-top: 6px;
    }
    .detail_content_top_right_address_end {
      font-size: 14px;
      background-color: #F6F6FC;
      margin-top: 16px;
      margin-left: 60px;
      padding: 20px;
      box-sizing: border-box;
      img {
        width: 480px;
      }
      .detail_content_top_right_address_end_top {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
      .detail_content_top_right_address_end_top_add {
        font-size: 30px;
        font-weight: 600;
        color: $colorA;
        margin-top: 10px;
        margin-left: 68px;
      }
    }
    .detail_content_top_right_address_info {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .detail_content_top_right_address_info_left,
      .detail_content_top_right_address_info_right {
        div {
          margin-bottom: 8px;
        }
        .color-r {
          color: $colorF3;
        }
        .btn {
          width: 160px;
          height: 39px;
          border: 1px solid $colorA;
          border-radius: 4px;
          line-height: 39px;
          text-align: center;
          margin-top: 24px;
        }
        .secondaryDevelopmentOne {
          color: $colorF3;
        }
        .chainHash {
          >span {
            vertical-align: top;
          }
          .chainHashInfo {
            //max-width: calc(100% - 100px);
            display: inline-block;
            //word-break: break-all;
          }
        }
      }
      .detail_content_top_right_address_info_left {
        .contract-btn {
          color: $colorA;
          background-color: #F6F6FC;
        }
      }

      .detail_content_top_right_address_info_right {
        .down-btn {
          background-color: $colorA;
          color: #ffffff;
        }
        .secondaryDevelopmentInfo {
          display: inline-block;
          word-break: break-all;
          max-width: calc(100% - 70px);
        }
        .secondaryDevelopment {
          >span {
            vertical-align: top;
          }
        }
      }
    }
    .detail_lastbutton {
      cursor: pointer;
      display: inline-block;
      width: 270px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 15px;
      background: $colorA;
      color: #fff;
      margin-top: 30px;
      &:hover {
        transition: all 0.3s;
        background: #1016EB;
      }
    }
  }
}
.detail_content_nav {
  // box-sizing: border-box;
  width: 100vw;
  .detail_content_nav_info {
    .detail_content_nav_list {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      padding: .38rem .42rem 0;
      font-size: .26rem;
      justify-content: space-between;
      background-color: #fff;
      .nav-item {
        flex: 1;
        text-align: center;
        padding: .3rem 0;
        cursor: pointer;
        a {
          color: $colorL1;
        }
        &.selected {
          border-bottom: 2px solid $colorA;
          a {
            color: $colorA;
          }
        }
      }
    }
  }
}
.productDetails,
.detailsDemo,
.userCase ,
.serviceSupport,
.apply{
  // margin: 32px 0;
  .title {
    padding: .55rem 0 .48rem;
    width: 100%;
    text-align: center;
    font-size: .36rem;
    color: #333;
    font-weight: 500;
    color: #333;
  }

}

.apply {
  display: flex;
  justify-content: center;
  padding: .74rem 1.06rem;
}

/deep/#productDetails {
  img {
    width: 100% !important;
  }
}
/deep/#detailsDemo {
  video {
    width: 100% !important;
  }
  // p {
  //   span {
  //     font-size: .28rem !important;
  //     color: #000 !important;
  //     font-weight: 400 !important;
  //   }
  // }
}
/deep/#userCase {
  img {
    width: 100% !important;
  }
}

.detailsDemo {
  box-sizing: border-box;
  width: 100vw !important;
  .title {
    display: inline-block;
  }
  .demo-button {
    display: flex;
    justify-content: space-between;
    padding: .4rem .35rem 0;
  }
}
.editor-content-view {
  // border-radius: 5px;
  padding: 0 .16rem;
  overflow-x: auto;
  video {
    width: 100%;
    border-radius: 8px;
  }
  img {
    //width: 100%;
    width: 100%!important;
    max-width: 100%!important;
    height: auto!important;
  }
}

.editor-content-view p,
.editor-content-view li {
  white-space: pre-wrap; /* 保留空格 */
}

.editor-content-view blockquote {
  border-left: 8px solid #d0e5f2;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f1f1f1;
}

.editor-content-view code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}
.editor-content-view pre>code {
  display: block;
  padding: 10px;
}

.editor-content-view table {
  border-collapse: collapse;
}
.editor-content-view td,
.editor-content-view th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.editor-content-view h1,
.editor-content-view h2,
.editor-content-view h3,
.editor-content-view h4,
.editor-content-view h5 {
  color: $colorT1;
}

.editor-content-view th {
  background-color: #f1f1f1;
}

.editor-content-view ul,
.editor-content-view ol {
  padding-left: 20px;
}

.editor-content-view input[type="checkbox"] {
  margin-right: 5px;
}

.item-style-26 {
  line-height: .37rem;
  font-size: .26rem;
  color: #6D7278;
  font-weight: 400;
}

.btn {
  width: 3rem;
  height: .8rem;
  // border: 1px solid $colorA;
  border-radius: .06rem;
  line-height: .8rem;
  font-size: .28rem;
  text-align: center;
}

.color-b {
  color: $colorF3;
}

.not-click {
  pointer-events: none;
}

.btn-gray {
  color: #fff !important;
  border-color: gray !important;
  background-color: gray !important;
}

/deep/img {
  width: 100%;
}

</style>
